.container {
    margin-top: 90px;
    margin-bottom: 90px;
    font-family: 'Roboto', sans-serif;

    h4 {
        color: lightgray;
        border-bottom: 1px solid white;
        padding: 10px;
    }

    h5 {
        color: lightgray;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    p {
        color: lightgray;
        padding: 10px;
        margin-bottom: 75px;
        font-family: 'Roboto', sans-serif;
        z-index: 999;
        background-color: var(--main-color);
    }

    .About-logo {
        width: 100%;
    }

    .col-xl-4 {

        padding: 0;
        border-radius: 15px;
        -webkit-box-shadow: 0px 0px 20px 1px rgba(255, 255, 255, 0.3);
        -moz-box-shadow: 0px 0px 20px 1px rgba(255, 255, 255, 0.3);
        box-shadow: 0px 0px 20px 1px rgba(255, 255, 255, 0.3);
        margin-bottom: 30px;
        margin-right: 30px;
        z-index: 999;

    }

    img {
        opacity: 0.9;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .Picture {
        width: 45px;
        height: 55px;
        margin: 10px;
    }

    .tehnology {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
        margin-top: 15px;
        margin-bottom: 15px;
        flex-wrap: wrap;
    }

    .icon {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .icon:hover {
        border-bottom: 2px double tomato;
        border-radius: 8px;
        transition: 0.2s;
    }

    @media screen and (max-width: 577px) {
        .row {
            margin-left: 0;
            margin-right: 0;
            flex-wrap: wrap;

        }
    }

    @media screen and (min-width: 1200px) {
        .row {
            margin-left: 0;
            margin-right: 0;
            flex-wrap: nowrap;

        }
    }
}