
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    
}
.content-wraper {
    width: 550px;
    transform-origin: center center;
    border-radius: 20px;
    margin-top: 120px;
    -webkit-box-shadow: 0px 0px 61px 4px rgba(255,255,255,1);
    -moz-box-shadow: 0px 0px 61px 4px rgba(255,255,255,1);
    box-shadow: 0px 0px 61px 4px rgba(255,255,255,1);
    ul{
        margin: 0;
        padding-left: 0;
    }
    #list{
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .btn{
        color: white;
        background-color: var(--main-color);
        width: 100%;
        border-radius: 20px;
        -webkit-box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
        -moz-box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
        box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
        }
        #btn-list-social{
            padding: 0 0 0 25px;
        }
        
        
        #Channels{
            display: flex;
            justify-content: space-between;
        }
        #suport{
            display: flex;
            justify-content: space-between;
        }
        #setup{
            display: flex;
            justify-content: space-between;
        }
        li{
            margin: 15px;
            border-radius: 20px;
            list-style: none;
            -webkit-box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
            -moz-box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
            box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
            
        } li:hover{
            background-color: gainsboro;
            a{color:black;}
        }button:hover{
            background-color: gainsboro;
            
        }
        
        .list-item-chanels {
            display: flex;
            margin: 15px;
            border-radius: 20px;
            list-style: none;
            -webkit-box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
            -moz-box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
            box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
        }
        a{
            text-decoration: none;
            color: white;
             display: flex;
            

        } span{
            
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            padding-left: 25px;
            padding-right: 25px;
        }h3{
            font-size: 16px;
            padding: 6px;
            margin-bottom: 0;
            
        }
        .list-item-setup {
            display: flex;
            margin: 15px;
            border-radius: 20px;
            list-style: none;
            padding-left: 25px;
            color: white;
            -webkit-box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
            -moz-box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);
            box-shadow: 0px 0px 10px 2px rgba(255,255,255,0.8);

        } 
        }
    
