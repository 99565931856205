.navbar {
    padding-left: 1rem;
    padding-right: 1rem;
    left: 0px;
    right: 0px;
    top: 0px;
    position: fixed;
    background-color: var(--main-color);
    z-index: 1000;
    box-shadow: 0px 5px 10px -2px rgb(255 255 255);
    font-family: 'Lobster', cursive;
    z-index: 1000;


    .navbar-brand {
        color: white;
    }

    .App-logo {
        width: 45px;
        opacity: 80%;
        animation: rotation 20s infinite linear;

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }

    h1 {
        font-size: 22px;
        margin: 0;
        color: darkgray;
    }

}

/*  

     #glow {
    
    border: none;
    outline: none;
    color: #fff;
    background: transparent;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    margin-right: 15px;
}

    #glow:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .1s ease-in-out;
    border-radius: 10px;
}

    #glow:active {
    color: #000
}

    #glow:active:after {
    background: transparent;
}

    #glow:hover:before {
    opacity: 1;
}

    #glow:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--main-color);
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
*/