
.footer{
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
    background-color: var(--main-color);
    padding: 8px 16px;
    box-shadow: 0px -5px 10px -2px rgb(255 255 255);
    z-index: 1000;
    .navbar-nav{
        justify-content: center;
        flex-direction: row;
    } 
    #glow{
        margin: 8px 0; 
        padding: 0 16px;
        color: white 
    }
    .activeLink{
        background-color: aliceblue;
        border-radius: 100px;
        color: black !important;
    }a{
        text-decoration: none;
    }
    }
